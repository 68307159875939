import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
          <a
            href="#main-content"
            className="element-invisible element-focusable"
          >
            Informationen zum Thema Durchfall
          </a>
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/chronischer-durchfall"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 first">
                      <a href="/loperamid/">Wie wirkt IMODIUM<sup>®</sup>?</a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727 active-trail">
                      <a
                        href="/fakten-uber-durchfall/"
                        className="active-trail"
                      >
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-durchfall-ursachen-behand"
                  className="block block-menu"
                >
                  <h2>Durchfall - Ursachen &amp; Behandlung</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/fakten-uber-durchfall/" title>
                          Fakten über Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/durchfallmythen/" title>
                          Tipps bei Durchfall
                        </a>
                      </li>
                      <li className="leaf">
                        <a href="/ursachen-von-durchfall/" title>
                          Ursachen von Durchfall
                        </a>
                      </li>
                      <li className="expanded">
                        <a href="/richtig-essen/" title>
                          Gesunde Essgewohnheiten
                        </a>
                        <ul className="menu">
                          {/*<li className="first leaf">
                            <a href="/nahrungsmittelallergie/" title>
                              Nahrungsmittelallergien
                            </a>
                          </li>
                          <li className="leaf">
                            <a href="/lebensmittelvergiftung/" title>
                              Lebensmittelvergiftung
                            </a>
                          </li>*/}
                          <li className="last leaf">
                            <a href="/rezepte/" title>
                              Leckere Rezepte
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="expanded">
                        <a href="/stress-durchfall/" title>
                          Stress und Durchfall
                        </a>
                        <ul className="menu">
                          <li className="first leaf">
                            <a href="/hohe-arbeitsbelastung/" title>
                              Hohe Arbeitsbelastung
                            </a>
                          </li>
                          <li className="last leaf">
                            <a href="/prufungssituationen/" title>
                              Prüfungssituationen
                            </a>
                          </li>
                        </ul>
                      </li>
                      {/*<li className="leaf active-trail">
                        <a
                          href="/chronischer-durchfall/"
                          title
                          className="active-trail active"
                        >
                          Chronischer Durchfall
                        </a>
                        </li>*/}
                      <li className="leaf">
                        <a href="/gastroenteritis/" title>
                          Magen-Darm-Grippe
                        </a>
                      </li>
                      {/*<li className="leaf">
                        <a href="/norovirus/" title>
                          Norovirus
                        </a>
                      </li>*/}
                      <li className="last leaf">
                        <a href="/durchfall-wahrend-ihrer-periode/" title>
                          Durchfall während Ihrer Periode
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="region region-sidebar">
                <div id="block-block-3" className="block block-block">
                  <div className="content">
                    <div
                      style={{
                        background:
                          "url(/sites/default/files/imodium-info-box-grafik_0.gif) no-repeat center top #eaf4f5",
                        padding: "50px 18px 20px 18px",
                        "-webkit-text-align": "center",
                        "text-align": "center",
                        color: "#484848"
                      }}
                    >
                      <h4 style={{
                        fontWeight: "bold",
                        fontSize: "30px",
                        lineHeight: "26px",
                        margin: "20px 0 10px 0",
                        fontFamily: "'Open Sans', sans-serif",
                      }}>
                        DIAGNOSE
                        <br />
                        REIZDARM
                      </h4>
                      <p>
                        <img
                          alt
                          src="/sites/default/files/teaser_stress_durchfall.jpg"
                          style={{
                            width: "174px",
                            height: "106px"
                          }}
                        />
                        <br />
                      </p>
                      <div
                        style={{
                          "-webkit-text-align": "left",
                          "text-align": "left",
                          "font-size": "13px",
                          color: "#767575",
                          padding: "15px 0",
                          "line-height": "18px"
                        }}
                      >
                        Etwa jeder fünfte Erwachsene leidet irgendwann im Leben
                        an Reizdarm-Symptomen. Finden Sie hier Informationen zu
                        Ursachen und Behandlung.
                        <br />
                        <div
                          style={{
                            margin: "10px 0 0 0"
                          }}
                        >
                          <a
                            className="georgia arrow_01"
                            href="/diagnose-reizdarm/"
                          >
                            Mehr erfahren
                          </a>
                        </div>
                      </div>
                    </div>
                    <p>&nbsp;</p>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Chronischer Durchfall
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="headimg border">
                      <img
                        typeof="foaf:Image"
                        src="/sites/default/files/gastroenteritis_0.jpg"
                        width="695"
                        height="286"
                      />{" "}
                    </div>{" "}
                    <div className="std-site">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Ursachen chronischer Durchfall
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            Die Diagnose ‚chronischer Durchfall‘ wird gestellt,
                            wenn Betroffene über den Zeitraum eines Monats oder
                            länger häufig von Durchfall geplagt sind. Da ein
                            wässriger Stuhl vor allem einen Verlust an
                            lebenswichtigen Elektrolyten und Flüssigkeit
                            bedeutet, können Schwäche und Dehydrierung die Folge
                            sein. Sie sollten daher Ihren Arzt aufsuchen.
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="left">
                          <div className="field field-name-field-sidebar-text field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div className="field-item even">
                                <h3>Tipp:</h3>
                                <p>
                                  Wenn Sie über längere Zeit immer wieder an
                                  Durchfall leiden, gehen Sie zu Ihrem Hausarzt.
                                  Bis die genaue Ursache feststeht kann einige
                                  Zeit vergehen, in der viele Betroffene meist
                                  sehr beunruhigt sind. Aber nur eine Diagnose
                                  kann Ihnen helfen, das Problem nachhaltig
                                  anzugehen.
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="right">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <p>
                                  Um die Ursache Ihres Durchfalls feststellen zu
                                  können, hat der Arzt die Möglichkeit, eine
                                  Stuhlprobe auf Parasiten oder Bakterien
                                  überprüfen zu lassen, eine Blutprobe zu
                                  untersuchen oder sogar eine Darmspiegelung zu
                                  veranlassen. Mit Hilfe einer optischen Linse,
                                  die am Ende eines dünnen Schlauchs aus
                                  Glasfaser sitzt kann dabei das Innere des
                                  Darms einer genauen Untersuchung unterzogen
                                  werden.
                                </p>
                                <p>
                                  Zu den möglichen Ursachen von chronischem
                                  Durchfall gehören unter anderem:
                                </p>
                                <ul>
                                  <li>
                                    Das{" "}
                                    <a href="/diagnose-reizdarm/">
                                      Reizdarmsyndrom
                                    </a>
                                    , im Englischen spricht man vom Irritable
                                    Bowel Syndrome, kurz IBS
                                  </li>
                                  <li>
                                    Eine entzündliche Darmerkrankung wie Colitis
                                    ulcerosa oder Morbus Crohn
                                  </li>
                                  <li>Parasiten</li>
                                </ul>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
